function selectTranslations(data) {
  return data.allFile.edges[0].node.childTranslationsJson
}

function selectProducts(data) {
  return data.allFile.edges[0].node.childTranslationsJson.products
}

function selectProductGroups(data) {
  return data.allFile.edges[0].node.childTranslationsJson.productGroups
}

function selectHomePageContent(data) {
  return selectTranslations(data).pages.home
}

function selectMenuPageContent(data) {
  return selectTranslations(data).pages.menu
}

function selectRestaurantsPageContent(data) {
  return selectTranslations(data).pages.restaurants
}

function selectCrownStandardPageContent(data) {
  return selectTranslations(data).pages.crownStandard
}

function selectAboutPageContent(data) {
  return selectTranslations(data).pages.about
}

function selectCampaignsPageContent(data) {
  return selectTranslations(data).pages.campaigns
}

function selectHalloweenPageContent(data) {
  return selectTranslations(data).pages.halloween
}

function selectCareersPageContent(data) {
  return selectTranslations(data).pages.careers
}

function selectFeedbackPageContent(data) {
  return selectTranslations(data).pages.feedback
}

function selectFeedbackSuccessPageContent(data) {
  return selectTranslations(data).pages.feedbackSuccess
}

function selectKidsMenuPageContent(data) {
  return selectTranslations(data).pages.kidsMenu
}

function selectNotFoundPageContent(data) {
  return selectTranslations(data).pages.notFound
}

function selectProductPageContent(data) {
  return selectTranslations(data).pages.product
}

function selectRestaurants(data) {
  return selectTranslations(data).restaurants
}

function selectSearchPageContent(data) {
  return selectTranslations(data).pages.search
}

function selectResponsibilityPageContent(data) {
  return selectTranslations(data).pages.responsibility
}

function selectPagesSearchIndex(data) {
  return data.allPageSearchIndex.edges[0].node.pagesSearchIndex
}

function selectProductsSearchIndex(data) {
  return data.allProductsSearchIndex.edges[0].node.productsSearchIndex
}

function selectProductGroupsSearchIndex(data) {
  return data.allProductGroupsSearchIndex.edges[0].node.productGroupsSearchIndex
}

module.exports = {
  selectAboutPageContent,
  selectCampaignsPageContent,
  selectHalloweenPageContent,
  selectCareersPageContent,
  selectCrownStandardPageContent,
  selectFeedbackPageContent,
  selectFeedbackSuccessPageContent,
  selectHomePageContent,
  selectKidsMenuPageContent,
  selectMenuPageContent,
  selectNotFoundPageContent,
  selectPagesSearchIndex,
  selectProductGroups,
  selectProductGroupsSearchIndex,
  selectProductPageContent,
  selectProducts,
  selectProductsSearchIndex,
  selectRestaurants,
  selectRestaurantsPageContent,
  selectSearchPageContent,
  selectResponsibilityPageContent,
  selectTranslations,
}
